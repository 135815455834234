import { t } from "i18next";
import { COLORS_DONUT_CHART, globalFields } from "../../../constants";
import CheckboxSimple from "../../components/CheckboxSimple";
import { fields } from "./constants";

export const formFields = {
  jobName: "jobName",
  deadlineStatus: "deadlineStatus",
  priority: "priority",
  status: "status",
  equipmentKindAndType: "equipmentKindAndType",
  responsibleCustomerUser:"responsibleCustomerUser",
  jobEquipments: "jobEquipments",
  responsibleOrganizationName: "responsibleOrganizationName",
  executiveUser: "executiveUser",
  ResponsibleCustomerUserName:"ResponsibleCustomerUserName",
  ExecutiveUserName:"ExecutiveUserName",
  responsibleCustomer: "responsibleCustomer",
  createdAtDate: "createdAtDate",
  deadlineDate: "deadlineDate",
  facilityName: "facilityName",
  fireCompartmentName: "fireCompartmentName",
  facilityAddress: "facilityAddress",
  ProtectionObjectId:"protectionObjectId",
  searchParams: "searchParams",
  equipmentNumberAndName: "equipmentNumberAndName",
  equipmentType: "equipmentType",
  responsibleOrganization: "responsibleOrganization",
  CreatedDateRange: "CreatedDateRange",
  deadLineDate: "deadLineDate",
  triggerCause: "triggerCause",
  tasks: "tasks",
  replacementTasks: "replacementTasks",
  replacementTask: "replacementTask",
  defectActNumber: "defectActNumber"
};

export const allColumns = [
  { name: formFields.createdAtDate, showName: "dateTime", value: false },
  { name: formFields.jobName, value: true, showName: "task" },
  { name: formFields.priority, value: false },
  { name: formFields.status, value: false },
  { name: formFields.deadlineStatus, showName: "state", value: false },
  { name: formFields.facilityName, value: false },
  { name: formFields.facilityAddress, showName: "address", value: false },
  { name: formFields.equipmentKindAndType, showName: "system", value: true },
  { name: formFields.jobEquipments, showName: "equipment", value: true },
  { name: formFields.executiveUser, showName: "executive", value: true },
  { name: formFields.responsibleCustomer, value: true },
  { name: formFields.deadlineDate, showName: "date_of_close", value: false },
  { name: formFields.replacementTasks, showName: "replacementTasks", value: false },
  { name: formFields.defectActNumber, showName: "defectActNumber", value: false }
];

export const getConfigFormModalSelect = (columns) =>
  columns?.map(({ name }) => ({
    name,
    label: `tableHeader:${name}`,
    type: "checkbox",
    rules: {},
    flexBasis: "50%",
    input: (props) => <CheckboxSimple {...props} />,
  }));

export const PRIORITY = {
  [t(`options_name:${fields.highty}`)]: { color: "var(--red)" },
  [t(`options_name:${fields.middle}`)]: { color: "var(--orange)" },
  [t(`options_name:${fields.lower}`)]: { color: "var(--green2)" },
};

export const STATUS = {
  [t(`${fields.completely}`)]: {
    color: "rgba(164, 168, 252, 0.5)",
  },
  [t(`${fields.inprogress}`)]: { color: "rgba(222, 247, 236)" },
  [t(`${fields.onreview}`)]: { color: "rgba(245, 158, 11, 0.3)" },
};

export const DEADLINE_STATUS_COLOR = {
  [t(`options_name:${globalFields.overdued_jobs_amount}`)]:
    COLORS_DONUT_CHART.overdued_jobs_amount,
  [t(`options_name:${globalFields.completed_intime_jobs_amount}`)]:
    COLORS_DONUT_CHART.completed_intime_jobs_amount,
  [t(`options_name:${globalFields.near_deadline_jobs_amount}`)]:
    COLORS_DONUT_CHART.near_deadline_jobs_amount,
  [t(`options_name:${globalFields.not_near_deadline_jobs_amount}`)]:
    COLORS_DONUT_CHART.not_near_deadline_jobs_amount,
};
