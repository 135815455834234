import React from "react";
import {

  ExecutiveUserNameSelectSimple,

} from "../Components";

import { t } from "i18next";

import InputText from "../../../../components/InputText";
import {FacilityEquipmentSelectGrouping} from "../Components";



export const formFields = {
  protectionObjectId:"protectionObjectId",
  recomendationDescription:"recomendationDescription",
  lines:"lines",
  equipmentId:"equipmentId",
  reasonDescription:"reasonDescription",
  executiveUserId:"executiveUserId",
  responsibleCustomerUserId:"responsibleCustomerUserId",

};

export const formConfigSection = (

) => [
  {
    name: formFields.recomendationDescription,
    label: t("label:recomendationDescription"),
    type: "text",
    rules: { required:true},
    input: (props) => <InputText {...props} />,
  },
  {
    name: formFields.executiveUserId,
    label: t("label:executiveUserName"),
    type: "select-text",
    rules: {},
    input: (props) => <ExecutiveUserNameSelectSimple {...props} />,
  },
  {
    name: formFields.responsibleCustomerUserId,
    label: t("label:responseUserName"),
    type: "select-text",
    rules: {},
    input: (props) => <ExecutiveUserNameSelectSimple {...props} />,
  },
];

export const equipmentsFieldArrayConfig = [
  {
    name: formFields.equipmentId,

    rules: { required:true},
    sx:{width:300},
    input: (props) => <FacilityEquipmentSelectGrouping {...props} />,
  },
  {
    name: formFields.reasonDescription,
    label: t("label:reasonDescription"),
    type: "text",
    
    sx:{width:300},
    rules: { required:true},
    input: (props) => <InputText {...props} />,
  },
];
