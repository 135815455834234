import React, { useEffect, useReducer, useState, useMemo } from "react";
import { useForm } from "react-hook-form";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import { Box, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import "../styles/styles.css";
import { formConfigSection, formFields, equipmentsFieldArrayConfig } from "./form-config";
import { useAppSelector } from "../../../../../hooks/redux";
import { useAction } from "../../../../../hooks/actions";
import { DEFAULT_ROLE_ID, ROLES, ROLE_TYPES } from "../../../../../constants";
import FormItem from "../../../../components/FormItem";
import {
  useGenerateDefectMutation
} from "../../../../../middleware/documentationApi";
import { useFieldArray } from "react-hook-form";
import {
  getRequestData,
} from "../helpers";
import { WarningValidationForm } from "../../../../components/Notifications";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const DefectModal = ({ open, setOpen, defectData,refetch,PrevselectedValues,setPrevselectedValues }) => {
  const { t } = useTranslation();
  const { setIsErrorSubmit, setIsSuccessSubmit } = useAction();
  const { info } = useAppSelector((state) => state.users);
  const { roleId, phoneVerified } = info || {};
  const roleUser = ROLES[roleId];
  const [isAppendDisabled, setAppendDisabled] = useState(false);
  const [copySelVal, setcopySelVal] = useState()
  const [IndexAppend, setIndexAppend] = useState(0);
  const DefectEquipments = defectData?.equipmentData.map(({ id, name }, index) => ({
    name: `${name}`,
    value: id,
  }))

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
    trigger,
    resetField,
    getValues
  } = useForm({
    mode: "onChange",
  });
  const { fields: equipments, append, remove } = useFieldArray({
    control,
    name: "lines"

  });
  useEffect(() => {
    setValue(formFields.executiveUserId, defectData?.executiveUserId)
    setValue(formFields.responsibleCustomerUserId, defectData?.responsibleCustomerUserId)
    //  setValue(formFields.workingCorrectly,defectData?.workingCorrectly)
    //  setValue(formFields.number,data?.number)

  }, [defectData])

  console.log("defectData", defectData)
  const [generateDefect, { error: errorgenerate, isSuccess: isSuccessgenerate, isLoading: isLoadinggenerate }] =
    useGenerateDefectMutation();
  const isWarningValidation = !!Object.keys(errors).length;
  //Get Object and Floor
  const configForm = useMemo(
    () =>
      formConfigSection(

        setValue,
        resetField
      ),
    [setValue, resetField]
  );

  const handleClose = () => {
    setOpen(false);
  };
  const onSubmit = async (data) => {
    const resData = { protectionObjectId:defectData?.protectionObjectId,...data}
    generateDefect(resData).then((result) => {
      setOpen(false);
      refetch(true)
    });
  };

  const actEquipOnChange = (value, index) => {
    console.log("ddata",index)
    let prevbb = PrevselectedValues
    if (prevbb[index]) {
      prevbb[index] = value
    } else {
      prevbb.push(value)
    }
    
    setPrevselectedValues(prevbb);
    let copyBB = DefectEquipments.filter(item => !PrevselectedValues.includes(item.value))
    setcopySelVal(copyBB)
     if (copyBB.length > 0)
         setAppendDisabled(false)
    /* let prevWork = PrevWorking
     if (prevWork[index]) {
         prevWork[index] = EquipCircuitExist.find(obj => obj.value === value)?.workingCorrectly.toString()
     } else {
         prevWork.push(EquipCircuitExist.find(obj => obj.value === value)?.workingCorrectly.toString())
     }
     setPrevWorkings(prevWork)
     let copyBB = EquipCircuitExist.filter(item => !PrevselectedValues.includes(item.value))
     setcopySelVal(copyBB)
     if (copyBB.length > 0)
         setAppendDisabled(false)
     */

  }
  useEffect(() => {
    setIsErrorSubmit(errorgenerate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorgenerate]);

  useEffect(() => {
    setIsSuccessSubmit(isSuccessgenerate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessgenerate]);


  const handleRemove = (index) => {
    remove(index)
    const changePrev = PrevselectedValues.filter((item, ItemIndex) => ItemIndex != index)

    setPrevselectedValues(changePrev)
    setAppendDisabled(false)


  }
  const handleAppend = () => {
    setAppendDisabled(true)

    setIndexAppend(IndexAppend + 1)

    //setSelectedValues(selectedValues.push(changedplumeEquip))
    append({})
  }
  return (
    <Dialog className="task_dialog" open={open} onClose={handleClose}>
      <Box sx={{ width: "800px", height: "500px" }}>
        <IconButton
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
            color: "black",
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <Box className="task_dialog-header">
          <Typography className="task_dialog-title">
            {"Дефектная ведемость"}
          </Typography>
          
        <Box className="task_dialog-head_buttons" sx={{ }}>
          <Button

            form="DefectForm"
            className="btn btn-primary-fill"
            type="submit"
          >
            {t("button:save")}
          </Button>


        </Box>
        </Box>
        <Typography sx={{ marginBottom: "20px", fontWeight: 500, fontSize: 24 }}>
          {`Объект: ${defectData?.protectionObjectName}`}
        </Typography>
        <form id="DefectForm"
          onSubmit={handleSubmit(onSubmit)} className="scrolled-form">
          <Box sx={{ marginBottom: "20px",border:"1px solid #c4c4c4",borderRadius:"4px",padding: "20px"}}>
            <Box sx={{display:"flex",marginLeft:"59px"}}>
              <Typography sx={{ marginBottom: "20px",width:"300px",textAlign:"center" }}>
                {`Неисправный узел, деталь, элемент, средство`}
              </Typography>
              <Typography sx={{ marginBottom: "20px",marginLeft:"15px",width:"300px",textAlign:"center" }}>
                {`Проявление дефекта`}
              </Typography>
            </Box>
            {(DefectEquipments && DefectEquipments?.length > 0) ?
              equipments?.map((field, index) => {

                return (

                  <Box sx={{
                    display: "flex",
                    gap: "15px",
                  }}>
                    <Button
                      className="btn btn-remove btn-basket-small"
                      onClick={() => { handleRemove(index) }}
                      sx={{ height: 34, alignSelf: "center", marginRight: "10px" }}
                    >
                      <DeleteForeverIcon />
                    </Button>
                    {equipmentsFieldArrayConfig.map((item) => {
                      return (
                        item.name == "equipmentId" ?
                          <Box>
                            <FormItem
                              control={control}
                              setValue={setValue}
                              key={`lines.${index}.${item.name}`}
                              actEquipOnChange={actEquipOnChange}
                              indexAxtEquip={index}
                              trigger={trigger}
                              optionsActs={DefectEquipments?.filter(item => !PrevselectedValues.includes(item.value) || (PrevselectedValues.includes(item.value) && PrevselectedValues[index] == item.value))}
                              value={PrevselectedValues[index]}
                              {...item}
                              name={`lines.${index}.${item.name}`}
                            />
                          </Box>
                          :
                          <Box>
                            <FormItem
                              control={control}
                              setValue={setValue}
                              trigger={trigger}
                              key={`lines.${index}.${item.name}`}
                              {...item}
                              name={`lines.${index}.${item.name}`}
                            />
                          </Box>
                      )


                    })}


                  </Box>

                )

              }) :
              <Box><Typography sx={{ fontWeight: 600, fontSize: "18px", textAlign: "center" }}> Нет оборудования для дефектной ведомости</Typography></Box>
            }
            <Button className="btn btn-outlined-grey" sx={{display: !DefectEquipments || DefectEquipments?.length == 0 ? "none" : "block" }} disabled={isAppendDisabled ? true : false} onClick={() => {

              handleAppend()

            }}>
              + Добавить
            </Button>
          </Box>
          {configForm &&
            configForm?.map(
              ({ id,
                name,
                label,
                input,
                expended,
                isCanDisabled,
                CustomFormItem,
                ...rest }) =>
                <Box
                  key={name}
                  sx={{
                    display: "grid",
                    gap: 2,
                    marginBottom: "20px"
                  }}
                >
                  <FormItem

                    control={control}
                    errors={errors}
                    name={name}

                    label={label}
                    input={input}
                    trigger={trigger}
                    {...rest}
                  />
                </Box>
            )}

        </form>
        {
          isWarningValidation && (
            <WarningValidationForm isWarning={isWarningValidation} />
          )
        }
      </Box>
      <Backdrop
        sx={{ color: "var(--white)", zIndex: 9999 }}

      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Dialog >
  );
};

export default DefectModal;
