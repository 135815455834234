import React from "react";
import { t } from "i18next";

import { formFields } from "./form-config";
import {
  deadlineStatusOptions,
  priorityOptions,
  statusOptions,
} from "./options";
import { SearchInbuilt } from "../../components/SearchInbuilt";
import SelectSimpleInbuilt from "../../components/SelectSimpleInbuilt";
import FormItem from "../../components/FormItem";
import DatePickerInbuild from "../../components/DatePickerInbuild";
import { EquipmentKindSelectGrouping } from "../Equipments/Components";
import { AddressInputInbuilt } from "../../components/AddressInputInbuilt";
import { SelectSimpleObject } from "../EquipmentList/Components"
import {
  PriorityCell,
  SimpleTextCell,
  StatusCell,
  JobEquipmentsCell,
  ClickableReplacementJobsCell,
  ClickableEquipmentCell,
  DeadlineStatusTextCell,
  ClickableJobNameCell,
  SimpleHeader,
  SimpleHHeader
} from "./Components";
import { Box } from "@mui/material";

export const getColumns = ({
  control,
  navigate,
  columnsFromStorage,
  jobName,
  setJobName,
  equipmentNumberAndName,
  setEquipmentNumberAndName,
  executiveCustomer,
  setExecutiveCustomer,
  responsibleCustomer,
  setResponsibleCustomer,
  defectActNumber,
  setDefectActNumber,
  replacementTask,
  setReplacementTask,
  facilityName,
  setFacilityName,
  searchAddress,
  setsearchAddress
}) =>
  [
    {
      id: formFields.createdAtDate,
      numeric: false,
      enableSorting: false,
      size: "160px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.createdAtDate],
      RowCell: (props) => <SimpleTextCell {...props} type="dateTime" />,
      HeaderCell: () => (
        <>
          <SimpleHHeader name={t("tableHeader:dateTime")} />

          <FormItem
            control={control}
            placeholder={"с"}
            name={`${formFields.searchParams}.${formFields.CreatedDateRange}.MinValue`}
            sx={{ padding: "1px 0" }}
            input={(props) => (
              <DatePickerInbuild
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                {...props}
              />
            )}
          />
          <FormItem
            control={control}
            placeholder={"по"}
            name={`${formFields.searchParams}.${formFields.CreatedDateRange}.MaxValue`}
            sx={{ padding: "1px 0" }}
            input={(props) => (
              <DatePickerInbuild
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                {...props}
              />
            )}
          />
        </>
      ),
    },
    {
      id: formFields.jobName,
      numeric: false,
      enableSorting: false,
      size: "125px",
      clickable: true,
      isSticky: true,
      isVisible: true,
      borderHeader: true,
      RowCell: (props) => <ClickableJobNameCell {...props} />,
      HeaderCell: () => (
        <Box sx={{ width: "110px" }}>
          <SimpleHeader name={t("tableHeader:task")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.${formFields.jobName}`}
            sx={{ padding: "12px 0" }}
            input={(props) => (
              <SearchInbuilt
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                searchValue={jobName}
                changeSearchValue={setJobName}
                {...props}
              />
            )}
          />
        </Box>
      ),
    },
    {
      id: formFields.priority,
      numeric: false,
      enableSorting: false,
      size: "135px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.priority],
      RowCell: (props) => <PriorityCell {...props} />,
      HeaderCell: () => (
        <>
          <SimpleHeader name={t("tableHeader:priority")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.${formFields.priority}`}
            label={t("options_name:everything")}
            options={priorityOptions}
            sx={{ padding: "12px 0" }}
            input={(props) => (
              <SelectSimpleInbuilt
                {...props}
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
              />
            )}
          />
        </>
      ),
    },
    {
      id: formFields.status,
      numeric: false,
      enableSorting: false,
      size: "150px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.status],
      RowCell: (props) => <StatusCell {...props} />,
      HeaderCell: () => (
        <>
          <SimpleHeader name={t("tableHeader:status")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.${formFields.status}`}
            label={t("options_name:everything")}
            options={statusOptions}
            sx={{ padding: "12px 0" }}
            width="150px"
            input={(props) => (
              <SelectSimpleInbuilt
                {...props}
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
              />
            )}
          />
        </>
      ),
    },
    {
      id: formFields.deadlineStatus,
      numeric: false,
      enableSorting: false,
      size: "180px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.deadlineStatus],
      RowCell: (props) => <DeadlineStatusTextCell {...props} />,
      HeaderCell: () => (
        <>
          <SimpleHeader name={t("tableHeader:state")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.${formFields.deadlineStatus}`}
            label={t("options_name:everything")}
            options={deadlineStatusOptions}
            sx={{ padding: "12px 0" }}
            width="180px"
            input={(props) => (
              <SelectSimpleInbuilt
                {...props}
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
              />
            )}
          />
        </>
      ),
    },
    {
      id: formFields.facilityName,
      numeric: false,
      enableSorting: false,
      size: "105px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.facilityName],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <>
          <SimpleHeader name={t("tableHeader:facilityName")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.${formFields.ProtectionObjectId}`}
            sx={{ padding: "12px 0" }}
            input={(props) => (
              <SelectSimpleObject
                {...props}
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
              />
              /*<SearchInbuilt
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                searchValue={facilityName}
                changeSearchValue={setFacilityName}
                {...props}
              />*/
            )}
          />
        </>
      ),
    },
    {
      id: formFields.facilityAddress,
      numeric: false,
      enableSorting: false,
      size: "275px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.facilityAddress],
      HeaderCell: () => (
        <Box sx={{ width: "250px" }}>
          <SimpleHeader name={t("tableHeader:address")} />
            <FormItem
              control={control}
              name={`${formFields.searchParams}.ProtectionObjectAddressFilter`}
             
              value={searchAddress}
              onChange={setsearchAddress}
              input={(props) => (
                <AddressInputInbuilt
                  className="select_in_built small-light"
                  {...props}
                  bgcolor={"var(--bgLightGrey)"}
                />
              )}
            />
        </Box>

      ),
    },
    {
      id: formFields.equipmentKindAndType,
      numeric: false,
      enableSorting: false,
      size: 200,
      clickable: false,
      isVisible: columnsFromStorage[formFields.equipmentKindAndType],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: (props) => (
        <>
          <SimpleHeader name={t("tableHeader:system")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.${formFields.equipmentType}`}
            label={t("options_name:everything")}
            sx={{ padding: "12px 0",width:"200px" }}
            input={(props) => (
              <EquipmentKindSelectGrouping
                itemoption={{
                  id: 0,
                  name: t("options_name:everything"),
                }}
                useShortTitle={true}
                {...props}
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                height={"24px"}
              />
            )}
          />
        </>
      ),
    },
    {
      id: formFields.jobEquipments,
      numeric: false,
      enableSorting: false,
      size: "280px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.jobEquipments],
      RowCell: (props) => <ClickableEquipmentCell {...props} />,
      HeaderCell: () => (
        <>
          <SimpleHeader name={t("tableHeader:equipment")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.${formFields.equipmentNumberAndName}`}
            sx={{ padding: "12px 0" }}
            input={(props) => (
              <SearchInbuilt
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                searchValue={equipmentNumberAndName}
                changeSearchValue={setEquipmentNumberAndName}
                {...props}
              />
            )}
          />
        </>
      ),
    },
    {
      id: formFields.executiveUser,
      numeric: false,
      enableSorting: false,
      size: "250px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.executiveUser],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <>
          <SimpleHeader name={t("tableHeader:executive")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.${formFields.ExecutiveUserName}`}
            sx={{ padding: "12px 0" }}
            input={(props) => (
              <SearchInbuilt
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                searchValue={executiveCustomer}
                changeSearchValue={setExecutiveCustomer}
                {...props}
              />
            )}
          />
        </>
      ),
    },
    {
      id: formFields.responsibleCustomerUser,
      numeric: false,
      enableSorting: false,
      size: "250px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.responsibleCustomer],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <>
          <SimpleHeader name={t("tableHeader:responsibleCustomer")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.${formFields.ResponsibleCustomerUserName}`}
            sx={{ padding: "12px 0" }}
            input={(props) => (
              <SearchInbuilt
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                searchValue={responsibleCustomer}
                changeSearchValue={setResponsibleCustomer}
                {...props}
              />
            )}
          />
        </>
      ),
    },
    {
      id: formFields.deadlineDate,
      numeric: false,
      enableSorting: false,
      size: "175px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.deadlineDate],
      RowCell: (props) => <SimpleTextCell {...props} type="date" />,
      HeaderCell: () => (
        <>
          <SimpleHeader name={t("tableHeader:date_of_close")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.${formFields.deadLineDate}`}
            sx={{ padding: "12px 0" }}
            input={(props) => (
              <DatePickerInbuild
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                {...props}
              />
            )}
          />
        </>
      )
    },
    {
      id: formFields.defectActNumber,
      numeric: false,
      enableSorting: false,
      size: "150px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.defectActNumber],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <>
          <SimpleHeader name={t("tableHeader:defectActNumber")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.${formFields.defectActNumber}`}
            sx={{ padding: "12px 0" }}
            input={(props) => (
              <SearchInbuilt
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                searchValue={defectActNumber}
                changeSearchValue={setDefectActNumber}
                {...props}
              />
            )}
          />
        </>
      ),
    },
    {
      id: formFields.replacementTasks,
      numeric: false,
      enableSorting: false,
      size: "150px",
      clickable: true,
      isSticky: true,
      isVisible: true,
      borderHeader: true,
      RowCell: (props) => <ClickableReplacementJobsCell {...props} />,
      HeaderCell: () => (
        <Box sx={{ width: "150px" }}>
          <SimpleHeader name={t("tableHeader:replacementTasks")} />
          <FormItem
            control={control}
            name={`${formFields.searchParams}.${formFields.replacementTask}`}
            sx={{ padding: "12px 0" }}
            input={(props) => (
              <SearchInbuilt
                className="select_in_built small-light"
                bgcolor={"var(--bgLightGrey)"}
                searchValue={replacementTask}
                changeSearchValue={setReplacementTask}
                {...props}
              />
            )}
          />
        </Box>
      ),
    }
  ].filter(({ isVisible }) => isVisible);
