import React, { useMemo, useEffect, useState } from 'react'
import { contentFormConfig } from '../../form-config'
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material'
import CloseIcon from "@mui/icons-material/Close";
import { getRequestData } from "../helpers";
import { getRequestDataFilesForAdd } from "../helpers";
import { useForm } from "react-hook-form";
import {
  useAddEquipmentMutation,
  useDeleteEquipmentMutation,
  useLazyGetEquipmentKindSelectElementsQuery,
  useValidateEquipmentNumbersMutation
} from "../../../../../middleware/equipmentsApi";
import { useManipulateFilesMutation } from "../../../../../middleware/apiUploadFile";
import { useAction } from "../../../../../hooks/actions";
import FormItem from '../../../../components/FormItem'
import SelectSimple from "../../../../components/SelectSimple";
import FormItemCheckboxControlled from '../../../../components/FormItem/FormItemCheckboxControlled'
import { t } from 'i18next'
import { SelectSimpleObject, SelectSimpleFloor } from "../../AddEquipmentLiteTree/Components";

const AddEquipmentModal = ({
  isActive,
  regulations,
  isSuccess,
  objectId,
  isOpen,
  setOpen,
  selectedKind,
  setselectedKind,
  refreshTree,
  selectedGroup,

  setselectedGroup,
  setselectedGroupId,
  setselectedSystem,
  selectedSystem,
  FloorId,
  formFields
}) => {

  const { setIsErrorSubmit, setIsSuccessSubmit, setErrorNotificationParams } =
    useAction();
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
    register,
    unregister,
    setValue,
    watch,
    getValues,
    trigger,
    reset,
    resetField,
  } = useForm({
    mode: "onChange",
  });

  const config = [
    {
      id: 1,
      name: formFields.objectMain,
      label: `label:${formFields.object}`,
      type: "select-text",
      rules: {},
      defaultValue: objectId,
      width: "320px",
      sx: { padding: "0px" },
      isCanDisabled: true,
      input: (props) => <SelectSimpleObject {...props} />,
    },

    {
      id: 2,
      name: formFields.floorMain,
      label: `label:${formFields.floor}`,
      type: "select-number",
      rules: {},
      defaultValue: FloorId,
      width: "250px",
      sx: { padding: "0px" },
      isCanDisabled: true,
      input: (props) => <SelectSimpleFloor {...props} />,
    },
  ];
  const [searchObject, setsearchObject] = useState("");
  const [equipTypesitems, setequipTypesitems] = useState([]);
  const [equipTypesnames, setequipTypesnames] = useState([]);
  const facId = watch(formFields.floorMain)
  const [
    manipulateFiles,
    { error: errorFile, isSuccess: isSuccessFile, isLoading: loadingFiles },
  ] = useManipulateFilesMutation();
  const [validateEquipmentNumbers, { error: validateError, data: isSuccessValidate }] = useValidateEquipmentNumbersMutation();
  const [
    addEquipment,
    {
      error: errorAdd,
      isSuccess: isSuccessAdd,
      isLoading: loadingAddEquipment,
    },
  ] = useAddEquipmentMutation();
  const [deleteEquipment] = useDeleteEquipmentMutation();
  const [GetEquipmentKindSelectElements] = useLazyGetEquipmentKindSelectElementsQuery();

  const isError = errorAdd || errorFile;
  const isLoadingRequest = loadingAddEquipment || loadingFiles;
  const Equipmentype = watch(formFields.equipmentTypeId);
  const hasEquipmentGroup = watch(formFields.hasEquipmentGroup);
  const equipmentTypeId = watch(formFields.equipmentTypeId);

  const EquipmentName = watch(formFields.name);

  useEffect(() => {
    if (selectedKind != null && equipTypesitems.length > 0) {
      const foundObject = equipTypesitems.find(obj => obj.id == selectedKind);
      const foundValue = foundObject ? foundObject.items.find(obj => obj.id == selectedSystem) : null
      console.log("Equipmentype", foundValue)
      setValue(formFields.equipmentTypeId, JSON.stringify({ parentId: selectedKind, name: `${foundObject.name},${foundValue?.name}`, id: selectedSystem, hasIntegration: foundValue?.hasIntegration }))

    }

  }, [equipTypesitems])

  useEffect(() => {
    setValue(formFields.objectMain, objectId)
  }, [objectId])
  useEffect(() => {
    setValue(formFields.floorMain, FloorId)
  }, [FloorId])
  
 // console.log("objectId", objectId, FloorId)

  useEffect(() => {
    console.log("hasEquipmentGroup", hasEquipmentGroup)

  }, [hasEquipmentGroup])

  const addEquipmentFormItems = useMemo(
    () =>
      contentFormConfig(
        isActive,
        regulations,
        isSuccess,
        objectId,
        setValue,
        getValues,
        resetField
      )[0],
    [isActive, regulations, isSuccess, objectId, selectedGroup, getValues, setValue, resetField]
  );
  const addEquip = (data) => {
    const { entities, files } = getRequestData(data, false);
    const newEntities = {
      ...entities,
      equipmentsCoordinates: [],
    };
    console.log("newEntities", newEntities)
    addEquipment(newEntities).then(({ data, error }) => {
      if (!error) {
        const resData = data;
        if (!resData?.length) {
          return;
        }
        const entityId =
          resData?.length > 1 && resData[0]?.equipmentGroup
            ? resData[0]?.equipmentGroup
            : resData[0]?.id;
        const entityType =
          resData?.length > 1 ? "EquipmentGroup" : "Equipment";
        const requestDataFiles = getRequestDataFilesForAdd(
          entityId,
          entityType,
          files
        );
        manipulateFiles(requestDataFiles).then(() => {
          if (error) {
            const allItemsIds = resData.map(({ id }) => id);
            for (const itemId of allItemsIds) {
              deleteEquipment(itemId);
            }
          }
          setOpen(false)
          reset()
          refreshTree(true, false)
          resetField(formFields.objectMain);
          resetField(formFields.floorMain);

          setselectedKind(null)
          setselectedGroup(null)
          setselectedGroupId(null)
          setselectedSystem(null)
        });
      }
    });
  }
  const onSubmit = async (data) => {
    let isErrorUniq = false
    const addEquipdata = {
      ...data
    }
    const equipmentsCountValue = getValues(formFields.equipmentsCount);
    if (getValues(formFields.hasEquipmentGroup)) {
      validateEquipmentNumbers({
        number: getValues(formFields.startNumber), EquipmentsCount: Number(getValues(formFields.equipmentsCount) || 1),
        ProtectionObjectId: objectId
      }).then((data) => {
        if (data?.error) {
          isErrorUniq = true
          setError('root.serverError', {
            typeMulti: data?.error.data.StatusCode,
            messageMulti: data?.error.data.Message
          })
        } else {
          addEquip(addEquipdata)
        }

      })
    } else {
      if (!equipmentsCountValue) {
        setValue(formFields.equipmentsCount, 1);
      }
      validateEquipmentNumbers({ number: getValues(formFields.number), protectionObjectId: objectId }).then((data) => {
        if (data?.error) {
          isErrorUniq = true
          setError('root.serverError', {
            type: data?.error.data.StatusCode,
            message: data?.error.data.Message
          })
        } else {
          addEquip(addEquipdata)
        }


      })
    }
  };

  useEffect(() => {
    setIsErrorSubmit(isError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    setIsSuccessSubmit(isSuccessAdd || isSuccessFile);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessAdd, isSuccessFile]);
  const handleClose = () => {
    reset()
    resetField(formFields.objectMain);
    resetField(formFields.floorMain);

    setOpen(false)
    setselectedKind(null)
    setselectedSystem(null)
  }

  return (
    <Dialog
      sx={{ padding: 10 }}
      fullWidth
      fullScreen
      className="modal"
      open={isOpen}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <DialogTitle>Добавить оборудование</DialogTitle>
        <DialogTitle className="modal-title-right">
          <IconButton onClick={() => handleClose()}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
      </Box>
      <DialogContent sx={{ padding: "20px 40px 0" }}>
        <form id="addEquipmentForm" onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "flex-start",
              alignItems: "flex-end",
              columnGap: "15px",
            }}
          >
            {config.map(({ id, name, label, type, isCanDisabled, ...rest }) => (
              <FormItem
                key={id}
                name={name}
                control={control}
                label={label}
                type={type}
                disabled={true}
                searchObject={searchObject}
                setsearchObject={setsearchObject}
                input={(props) => <SelectSimple sx={{ marginRight: "20px" }} {...props} />}
                {...rest}
              />
            ))}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "flex-start",
              alignItems: "flex-end",
              columnGap: "15px",
            }}
          >

            {
              addEquipmentFormItems.items.map(({
                id,
                name,
                label,
                input,
                expended,
                flexBasis,
                CustomFormItem,
                ...rest
              }) =>
                name != "fireCompartmentId" && (
                  CustomFormItem ? (
                    <Box key={id} className="resizing_basic_forms">
                      <CustomFormItem
                        key={name || id}
                        control={control}
                        errors={errors}
                        errorTyp={errors}
                        register={register}
                        unregister={unregister}
                        setValue={setValue}
                        name={name}
                        label={label}
                        input={input}
                        expended={expended}
                        trigger={trigger}
                        {...rest}
                      />
                    </Box>
                  ) : expended && rest.type === "checkbox" ? (
                    <FormItemCheckboxControlled
                      key={name}
                      control={control}
                      errors={errors}
                      errorTyp={errors}
                      register={register}
                      unregister={unregister}
                      setValue={setValue}

                      name={name}
                      label={label}
                      input={input}
                      expended={expended}
                      trigger={trigger}
                      {...rest}
                    />
                  ) : (((!hasEquipmentGroup && name == "number") || ((equipmentTypeId ? JSON.parse(equipmentTypeId)?.hasIntegration : false) && (name == "plume" || name == "integrationNumber")) || (name != "number" && name != "plume" && name != "integrationNumber")) &&
                    <Box key={id} sx={{ flexBasis: flexBasis, alignSelf: "flex-start !important" }}>
                      <FormItem
                        key={name}
                        control={control}
                        errors={errors}
                        errorTyp={errors}
                        objectId={objectId}
                        selectedKind={selectedKind}
                        setequipTypesitems={setequipTypesitems}
                        setequipTypesnames={setequipTypesnames}
                        selectedSystem={selectedSystem}
                        selectedGroup={selectedGroup}
                        Equipmentype={Equipmentype}
                        islite={true}
                        name={name}
                        disabled={(name == "fireCompartmentId" || (selectedKind != null && selectedSystem != null && name == "equipmentTypeId") || (selectedGroup != null && name == "name")) ? true : false}
                        label={label}
                        input={input}
                        trigger={trigger}
                        {...rest}
                      />
                    </Box>
                  ))
              )
            }
          </Box>
        </form>
      </DialogContent>

      <Button
        form="addEquipmentForm"
        className="btn btn-primary-fill"
        sx={{
          width: "150px", alignSelf: "end",
          margin: "20px", marginRight: "40px"
        }}
        type="submit"
      >
        {t("button:save")}
      </Button>
    </Dialog>
  )
}

export default AddEquipmentModal